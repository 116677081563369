import React, {useState, useRef, useEffect} from "react"
import { connect } from "react-redux"
import Formulario from '../Contact.jsx'
import Card from '../property-card'

import {getDescription,descriptionIsNull,getWhatsapp, getMessageWhatsapp, getVideos} from '../../helpers/helper.rendering'
import {getTags,getBluePrints, getFakeAddres, getLocation,getTour, getLastVideo, getOperations, getType, getCustomTags, getNameProducer, getPictureProducer, getCellphoneProducer, getEmailProducer, getTours} from '../../helpers/helper.properties'
// import Breadcrumbs from "../breadcrumbs";

import Map from '../map'

import SimpleReactLightbox, { SRLWrapper, useLightbox }from 'simple-react-lightbox'
import { graphql, useStaticQuery } from 'gatsby';

import Loading from '../../components/Loading'
import OwlCarousel from 'react-owl-carousel3';
import { updateRouter } from "../../helpers/helper.filters.jsx"
import { useLocation } from "@reach/router"
import Slider from "../slider.jsx"
import FilesPro from "../Emprendimiento/files-pro.jsx"

const options = {
    settings: {
        overlayColor: "rgba(0, 0, 0, 0.9)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,
    },
    buttons: {
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        iconColor: "rgba(255,255,255, 1)",
    },
    caption: {
        captionColor: "#000",
        captionFontFamily: "Raleway, sans-serif",
        captionFontWeight: "300",
        captionTextTransform: "uppercase",
    }
};

const options_slider = {
    // loop:true,
    // smartSpeed:1000,
    // stagePadding:10,
    center:false,
    // autoplay:true,
    margin:15,
    items:3,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        992: {
            items: 3,
        },
    },
}

const About = ({property,similar_properties,loading,dispatch}) => {
    const history = useLocation();
    const [charMax,setCharMax] = useState(1000)
    const { openLightbox, closeLightbox } = useLightbox()
    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            global_email
            global_phone
            template_version
            proFeatures{
                mediaBar
            }
            branch_office{
              contact {
                phone
                mail
                whatsapp
              }
            }
        }
    }`)

    const MediaPro = realEstate.template_version === 'PRO' ? true : false;
    const {mediaBar} = realEstate.proFeatures
    const Producer_Module = false;
    
    const slider_similar = useRef()

    const checkSimilar = (property,properties) => {
        if(properties){
            return properties.filter(p => p.id !== property.id)
        }
    }

    const createLinkProducer = (producer) => {
        // if(updateRouter(filters,true)){
            // const splitter = updateRouter(filters,true)?.split("/").filter(element => !element?.includes("producer") && element !== '')
            let url = ''
            // for (const iterator of splitter) {
            //     url += iterator + "/"
            // }
            if(producer){
                if(history.pathname.toLowerCase().includes("alquiler")){
                    return  "/alquiler/" + url + "producer-" + producer?.id + "-" + producer?.name?.toLowerCase().replaceAll(" ","-").replaceAll(".","").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                }
                else{
                    return  "/venta/" + url + "producer-" + producer?.id + "-" + producer?.name?.toLowerCase().replaceAll(" ","-").replaceAll(".","").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                }
            }
        // }
        return ''
    }

    return (
        <section class="ficha-about prop">
            <div class="container-fluid">
                <div class="row no-gutters pb-5">
                    {/* <div className="col-12">
                        {
                            property ?
                             <Breadcrumbs
                                props={[
                                    { name: realEstate.name, route: "/", location: "" },
                                    { name: getOperations(property)[0] , route:   "/" + getOperations(property)[0].toLowerCase(), location: ""},
                                    { name: getType(property) , route:   "/" + getOperations(property)[0].toLowerCase() + "/" + getType(property).toLowerCase(), location: ""},
                                    { name: getFakeAddres(property) , route:   "", location: ""},
                                ]}
                                />
                                :''
                        }
                    </div> */}
                    <div class="col-lg-7">
                        <h4 class="mb-3">Descripción de la propiedad</h4>
                        <p class="description-ficha">{property && 
                            getDescription(property,charMax).map((description,index) => (
                               <>
                                {description}
                                <br />
                               </>
                            ))
                        }
                        {1000 < property?.description?.length &&
                        <>
                            {charMax === 1000 && !descriptionIsNull(property) && <span class="d-block text-right text-expand"><a onClick={() => setCharMax(9999)}>Ver más</a></span>}
                            {charMax === 9999 && <span class="d-block text-right text-expand"><a onClick={() => setCharMax(1000)}>Ver menos</a></span>}
                        </>
                        }
                        </p>
                    </div>
                    <div class={"col-lg-4 offset-lg-1 "}>
                        <div className={(getTags(property).length > 0 ? '' : 'd-none') }>
                            <h4 class="mb-4 mt-5 mt-lg-auto ">Características generales</h4>
                            <div class="row no-gutters amenities">
                                {property && getTags(property).map((tag,index) => (
                                    <div class="col-6 mb-3 item align-items-center d-flex">
                                        <i class="icon-check"></i>
                                        <p>{tag.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={"mt-lg-4 " + (getCustomTags(property)?.find(tag => tag.group_name?.toLowerCase() === 'especales')?.length > 0 ? '' : 'd-none') }>
                            <h4 class="mb-4 mt-5 mt-lg-auto ">Características especiales</h4>
                            <div class="row no-gutters amenities">
                                {property && getCustomTags(property).map((tag,index) => (
                                    tag.group_name?.toLowerCase() === 'especiales' &&
                                    <div class="col-6 mb-3 item align-items-center d-flex">
                                        <i class="icon-check"></i>
                                        <p style={{lineHeight:"1"}}>{tag.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
               
                </div>
                {!mediaBar &&
                    <>
                        <div class={"row global-view blueprints pt-5 mt-3 " + (getBluePrints(property).length > 0 ? '' : ' d-none')} id="planos">
                            <div className="col-12">
                                <Slider files={getBluePrints(property)} />
                            </div>
                        </div>
                        <div id="video" class={"row global-view pt-5 mt-3 " + (getLastVideo(property) === undefined ? 'd-none' : '')}>
                            <div className="col-12">
                                <Slider files={getVideos(property)} type={"videos"} />
                            </div>
                        </div>
                        <div id="tour360" class={"row global-view pt-5 mt-3 " + (getTours(property).length === 0 ? 'd-none' : '')}>
                            <div className="col-12">
                                <Slider files={getTours(property)} type={"tours"} />
                            </div>
                        </div>
                    </>
                }
            </div>
            <FilesPro files={property.files || []} />
            <div class="container-fluid">
                <div class="row global-view pt-5 mt-3 ubicacion-main">
                    <div class="col-lg-12">
                        <h4 class="section-name">Ubicación</h4>
                        <p class="prop-title mb-lg-4 mb-4 pb-lg-0 pb-1">{getFakeAddres(property)}, {getLocation(property)}</p>
                    </div>
                    <div class="col-12">
                        <Map coordenadas={{ geo_lat: property.geo_lat, geo_long: property.geo_long }} />
                    </div>
                </div>
                <div class="row contacto-main pb-5" id="contacto-section">
                    <div class="col-lg-5 col-12">
                        <h2>Consultanos por <br class="d-none d-lg-block" /> esta  propiedad </h2>
                        <div class="content-contact">
                            {Producer_Module && 
                                <div className="div_broker_card"> 
                                    <a  target="_blank" href={createLinkProducer(property?.producer)}>
                                        <div className="header_broker d-flex justify-content-between justify-content-lg-start">
                                            {( getPictureProducer(property) && <img className='d-none d-lg-block' src={getPictureProducer(property)} alt="" />)}
                                            <div className="info_broker d-flex flex-column justify-content-between">
                                                <div className="">
                                                    {( getNameProducer(property) && <h3 className='contacto mb-1'>contacto</h3>)}
                                                    <h4 className='name_broker'>{getNameProducer(property)}</h4>
                                                </div>
                                                <a href={createLinkProducer(property?.producer)} className="btn btn-producer btn-white">Ver otras propiedades de {getNameProducer(property)?.split(" ")[0]}</a>
                                            </div>
                                            {( getPictureProducer(property) && <img className='d-block d-lg-none' src={getPictureProducer(property)} alt="" />)}
                                        </div>
                                        <div className="contacto_broker">
                                            <hr />
                                            <a className={!getCellphoneProducer(property) && 'd-none'} target='_blank' href={getWhatsapp(getCellphoneProducer(property),getMessageWhatsapp({},property,location.href))}><h5><i className="icon-whatsapp-solid "></i>+{getCellphoneProducer(property)}</h5></a>
                                            {/* <a target="_blank" href={"https://api.whatsapp.com/send?phone=+549" + (realEstate.branch_office[0].contact.whatsapp.replaceAll("-",""))}>Whatsapp {realEstate.branch_office[0].contact.whatsapp}</a> */}
                                            <a className={"mailto " + (!getEmailProducer(property) && 'd-none') } href={"mailto:" + getEmailProducer(property) + "?subject=" + property?.type?.name + " - " + property?.fake_address +"&body=" + getMessageWhatsapp({},property,location.href)}><h5 className="mb-0"><i className="icon-email-solid"></i>{getEmailProducer(property)}</h5></a>
                                            <hr className="d-block d-lg-none" />
                                        </div>
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                    <div class="col-lg-7 col-12 mt-4 mt-lg-0">
                        <div class="row justify-content-end">
                            <div class="col-lg-11">
                                <Formulario property={property} from={'property'} />
                                <div id="alert-message"></div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={"row mb-5 " + (checkSimilar(property,similar_properties).length === 0 && "d-none")}>
                    <div className="col-12">
                        <h4 class="mb-5 mt-4">Propiedades similares</h4>
                    </div>
                        {checkSimilar(property,similar_properties).map((prop,index) => (
                            index < 3 &&
                            <div className="col-lg-4">
                                <Card key={index} basic={false} property={prop}/>
                            </div>
                        ))}
                </div>
            </div>
        </section>
    )
}

export default connect(state => ({
    property: state.properties.property,
    similar_properties: state.properties.similar_properties,
    loading: state.properties.loading,
}),null)(About);